/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/docs/guides/auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function Input({
    hasError = false,
    style = {},
    type = "text",
    ...props
}) {
    const inputStyle = {
        backgroundColor: hasError ? "#FFEFEB" : "white",
        border: hasError ? "1px solid #FF3100" : "1px solid #ccc",
        color: hasError ? "1px solid #FF3100" : "1px solid #ccc",
        fontSize: "15px",
        padding: "12px 14px",
        display: "block",
        width: "100%",
        fontFamily: "'ABC Diatype Regular', sans-serif",
        borderRadius: "8px",
        outline: "none",
    }
    const cstyle = {
        ...inputStyle,
        ...style,
    }
    const { dangerouslySetInnerHTML, children, ...safeProps } = props
    return (
        <input
            // hasError={hasError}
            type={type}
            style={cstyle}
            {...safeProps}
        />
    )
}

// Styles are written in object syntax
// Learn more: https://reactjs.org/docs/dom-elements.html#style
// const containerStyle = {
//     border: "1px solid #4B4B4B",
//     fontSize: 14,
//     height: 50,
//     padding: "12px 14px",
//     display: "block",
//     width: "100%",
//     fontFamily: "F37Neuro, sans-serif",
//     borderRadius: 4,
// }
